import { memo } from 'react';

import { getDefaultWorkspace, getWorkspaceById } from '@/app/workspaces/models/workspaces';
import { useAppSelector } from '@/core/redux/hooks';
import Tooltip from '@/ui/components/Tooltip';

export interface Props {
    workspaceId: string;
}

const WorkspaceBadge = ({ workspaceId }: Props) => {
    const defaultWorkspaceId = useAppSelector(getDefaultWorkspace)?.id;
    const workspaceName = useAppSelector((state) => getWorkspaceById(state, workspaceId))
        ?.attributes?.name;

    const shouldShowBadge = !!workspaceName && defaultWorkspaceId !== workspaceId;

    if (!shouldShowBadge) {
        return null;
    }

    return (
        <Tooltip content={workspaceName}>
            <div className="mr-4 hidden h-7 max-w-[160px] truncate rounded-md bg-purple-100 px-2 py-1 text-purple-500 sm:block">
                <span className="text-xs font-medium">{workspaceName}</span>
            </div>
        </Tooltip>
    );
};

export default memo(WorkspaceBadge);
