import { memo } from 'react';

import type { ReactNode } from 'react';

interface Props {
    children?: ReactNode;
}

const SectionHeading = ({ children }: Props) => (
    <p className="mb-1 ml-6 mt-3 text-xs font-medium text-gray-500">{children}</p>
);

export default memo(SectionHeading);
