import { Command } from 'cmdk';
import { useTranslation } from 'next-i18next';

import { getRecentCampaigns } from '@/app/cmdk/models/recentCampaigns';
import { useAppSelector } from '@/core/redux/hooks';

import FunnelItem from '../components/FunnelItem';
import SectionHeading from '../components/SectionHeading';

import type { KeyboardEvent } from 'react';

export interface Props {
    handleSelect: (value: string) => void;
    itemOnKeyDown: (evt: KeyboardEvent<HTMLDivElement>, value: string) => void;
}

const RecentFunnels = ({ handleSelect, itemOnKeyDown }: Props) => {
    const { t } = useTranslation('common');
    const campaigns = useAppSelector(getRecentCampaigns);

    return (
        <Command.Group
            heading={<SectionHeading>{t('cmdk-funnels')}</SectionHeading>}
            className="text-gray-400"
        >
            {campaigns?.map((campaign) => (
                <FunnelItem
                    key={campaign?.id}
                    campaign={campaign}
                    handleSelect={handleSelect}
                    itemOnKeyDown={itemOnKeyDown}
                    withWorkspaceBadge
                />
            ))}
        </Command.Group>
    );
};

export default RecentFunnels;
