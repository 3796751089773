import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Command } from 'cmdk';
import Image from 'next/image';
import { memo } from 'react';
import { useCallback } from 'react';

import {
    getCampaignName,
    getCampaignStatus,
    getCampaignIsArchived,
    getCampaignCoverImage,
    getCampaignWorkspaceId,
    getCampaignContactCount,
} from '@/app/campaigns/helpers';
import { tw } from '@/utils/tw';

import WorkspaceBadge from './WorkspaceBadge';
import ContactsBadge from '../components/ContactsBadge';
import StatusBadge from '../components/StatusBadge';

import type { CampaignResource, CampaignStatus } from '@/app/campaigns/types';
import type { KeyboardEvent } from 'react';

export interface Props {
    campaign: CampaignResource;
    withWorkspaceBadge?: boolean;
    handleSelect: (value: string) => void;
    itemOnKeyDown: (evt: KeyboardEvent<HTMLDivElement>, value: string) => void;
}

export const itemClassName = tw`group flex cursor-pointer items-center justify-between px-6 py-2 text-gray-600 focus:outline-none data-[selected=true]:bg-gray-100`;

const FunnelItem = ({ campaign, withWorkspaceBadge, handleSelect, itemOnKeyDown }: Props) => {
    const name = getCampaignName(campaign);
    const status = getCampaignStatus(campaign) as CampaignStatus;
    const isArchived = getCampaignIsArchived(campaign);
    const numOfContacts = getCampaignContactCount(campaign);
    const workspaceId = getCampaignWorkspaceId(campaign);

    // Image
    const coverImage = getCampaignCoverImage(campaign);
    const image = `${coverImage?.split('?')[0]}?ar=1:1&fit=crop&auto=compress&w=80`;

    const value = `${name} [funnel-${campaign.id}]`;

    const handleKeyDown = useCallback(
        (value: string) => (evt: KeyboardEvent<HTMLDivElement>) => {
            itemOnKeyDown(evt, value);
        },
        [itemOnKeyDown],
    );

    return (
        <Command.Item
            key={campaign.id}
            className={itemClassName}
            onSelect={handleSelect}
            value={value}
            tabIndex={0}
            onKeyDown={handleKeyDown(value)}
        >
            <div className="flex min-w-0 items-center justify-start">
                <div className="relative size-10 shrink-0 overflow-hidden rounded-md shadow-border-baked-in-very-close">
                    <Image src={image} alt={`${name} cover image`} height={40} width={40} />
                </div>

                <p className="ml-4 mr-3 truncate">{name}</p>
            </div>

            <div className="flex shrink-0 items-center justify-end">
                {withWorkspaceBadge && <WorkspaceBadge workspaceId={workspaceId} />}

                <StatusBadge status={status} archived={isArchived} />

                <ContactsBadge numOfContacts={numOfContacts} />

                <ChevronRightIcon className="ml-4 size-5 shrink-0 text-gray-500" />
            </div>
        </Command.Item>
    );
};

export default memo(FunnelItem);
