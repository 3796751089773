import { Command } from 'cmdk';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';

import {
    getOtherCampaigns,
    getOtherPagination,
    getWorkspaceCampaigns,
    getWorkspacePagination,
    fetchNextOtherCampaigns,
    fetchNextWorkspaceCampaigns,
} from '@/app/cmdk/models/workspaceCampaigns';
import { useAppSelector } from '@/core/redux/hooks';
import LoadMore from '@/ui/components/LoadMore';

import FunnelItem from '../components/FunnelItem';
import SectionHeading from '../components/SectionHeading';

import type { KeyboardEvent } from 'react';

export interface Props {
    handleSelect: (value: string) => void;
    itemOnKeyDown: (evt: KeyboardEvent<HTMLDivElement>, value: string) => void;
}

const WorkspaceFunnels = ({ handleSelect, itemOnKeyDown }: Props) => {
    const { t } = useTranslation('common');

    const workspaceCampaigns = useAppSelector(getWorkspaceCampaigns);
    const otherCampaigns = useAppSelector(getOtherCampaigns);
    const hasNextWorkspaceCampaignsPage = !!useAppSelector(getWorkspacePagination)?.next;
    const hasNextOtherCampaignsPage = !!useAppSelector(getOtherPagination)?.next;

    return (
        <>
            {/* Workspace Campaigns */}
            {!!workspaceCampaigns?.length && (
                <Command.Group
                    className="text-gray-400"
                    heading={<SectionHeading>{t('cmdk-workspace-funnels')}</SectionHeading>}
                >
                    {workspaceCampaigns?.map((campaign) => (
                        <FunnelItem
                            key={campaign?.id}
                            campaign={campaign}
                            handleSelect={handleSelect}
                            itemOnKeyDown={itemOnKeyDown}
                        />
                    ))}
                </Command.Group>
            )}

            {!!hasNextWorkspaceCampaignsPage && (
                <LoadMore fetchNextPageFn={fetchNextWorkspaceCampaigns} className="h-10" />
            )}

            {/* Other Campaigns */}
            {!!otherCampaigns?.length && (
                <Command.Group
                    className="text-gray-400"
                    heading={<SectionHeading>{t('cmdk-all-funnels')}</SectionHeading>}
                >
                    {otherCampaigns?.map((campaign) => (
                        <FunnelItem
                            key={campaign?.id}
                            campaign={campaign}
                            handleSelect={handleSelect}
                            itemOnKeyDown={itemOnKeyDown}
                            withWorkspaceBadge
                        />
                    ))}
                </Command.Group>
            )}

            {!!hasNextOtherCampaignsPage && (
                <LoadMore fetchNextPageFn={fetchNextOtherCampaigns} className="h-10" />
            )}
        </>
    );
};

export default memo(WorkspaceFunnels);
