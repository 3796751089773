import { ArrowUpIcon, ArrowDownIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';

const footerTagClassName =
    'flex size-6 items-center justify-center rounded-md border bg-gray-100 text-sm';

const Footer = () => {
    const { t } = useTranslation('common');

    return (
        <div className="flex h-11 cursor-default items-center justify-end space-x-4 border-t px-6 py-2 text-xs font-medium text-gray-500">
            <div className="flex items-center">
                <div className="flex space-x-1">
                    <div className={footerTagClassName}>
                        <ArrowUpIcon className="size-4" />
                    </div>
                    <div className={footerTagClassName}>
                        <ArrowDownIcon className="size-4" />
                    </div>
                </div>
                <span className="ml-2">{t('select')}</span>
            </div>

            <div className="flex items-center">
                <div className={footerTagClassName}>
                    <kbd className="-mt-0.5">↵</kbd>
                </div>
                <span className="pl-2">{t('open')}</span>
            </div>
        </div>
    );
};

export default memo(Footer);
