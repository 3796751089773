import { Transition, Dialog } from '@headlessui/react';
import { Command } from 'cmdk';
import { useRouter } from 'next/router';
import { Fragment, useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { getOpen, openMenu, getSearch } from '@/app/cmdk/models/menu';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

import { getIsCmdK } from '../../helper';
import { fetchCampaigns } from '../../models/campaigns';
import { CommandMenuType } from '../../types';

import type { ReactNode } from 'react';

export interface Props {
    children: ReactNode;
    hide: () => void;
}

const MenuWrapper = ({ children, hide }: Props) => {
    const dispatch = useAppDispatch();
    const { pathname } = useRouter();
    const open = useAppSelector(getOpen);
    const search = useAppSelector(getSearch);
    const { canUpdate } = useUserCapabilities(CapabilitiesTarget.Campaign);

    const [value, setValue] = useState('[account-settings]');

    const ref = useDetectClickOutside({ onTriggered: hide });

    // Toggle the menu when ⌘K is pressed
    const handleKeydown = useCallback(
        (evt: KeyboardEvent) => {
            if (getIsCmdK(evt) && canUpdate) {
                if (!open) {
                    const isWorkspaceRoute = pathname.includes('/workspaces');

                    dispatch(
                        openMenu(
                            isWorkspaceRoute ? CommandMenuType.workspace : CommandMenuType.default,
                        ),
                    );
                } else {
                    hide();
                }
            }
        },
        [canUpdate, dispatch, hide, open, pathname],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);

        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [canUpdate, dispatch, handleKeydown, hide, open]);

    useEffect(() => {
        // Fetch campaigns
        if (open) {
            dispatch(fetchCampaigns(canUpdate));
        }
    }, [dispatch, search, open, canUpdate]);

    return (
        <Transition appear show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={hide}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-out duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-800/50" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Command
                            value={value}
                            onValueChange={(value) => {
                                setValue(value);
                            }}
                            label="Global Command Menu"
                            className="w-full"
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-200"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-out duration-150"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div
                                    className="mx-auto w-full max-w-screen-sm rounded-xl bg-white text-gray-800 shadow-xl"
                                    ref={ref}
                                >
                                    {children}
                                </div>
                            </Transition.Child>
                        </Command>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default MenuWrapper;
