import { CAMPAIGN_STATUS_KEYS, CAMPAIGN_STATUS_MAP, NAME } from '@/app/campaigns/constants';

import { useTranslation } from 'next-i18next';
import { memo } from 'react';

import { cn } from '@/utils/cn';

import type { CampaignStatus } from '@/app/campaigns/types';

export interface Props {
    status: CampaignStatus;
    archived: boolean;
}

const StatusBadge = ({ status, archived }: Props) => {
    const { t } = useTranslation(NAME);
    const isSuccess = status === CAMPAIGN_STATUS_MAP.live.text && !archived;
    const isWarning = status === CAMPAIGN_STATUS_MAP.building.text && !archived;
    const isDanger =
        (status === CAMPAIGN_STATUS_MAP.offline.text ||
            status === CAMPAIGN_STATUS_MAP.error.text) &&
        !archived;
    const isNeutral =
        status === CAMPAIGN_STATUS_MAP.draft.text ||
        archived ||
        !CAMPAIGN_STATUS_KEYS.includes(status);

    const translation = archived ? 'archived' : status;

    const badgeClass = cn(
        'mr-2 inline-block flex h-7 w-min flex-initial items-center rounded-md px-3',
        {
            'bg-green-50': isSuccess,
            'bg-yellow-50': isWarning,
            'bg-red-50': isDanger,
            'bg-gray-100 group-data-[selected]:bg-gray-200': isNeutral,
        },
    );

    const textClass = cn('text-xs font-medium capitalize', {
        'text-green-500': isSuccess,
        'text-yellow-500': isWarning,
        'text-red-500': isDanger,
        'text-gray-500': isNeutral,
    });

    return (
        <div className={badgeClass}>
            <p className={textClass}>{t(translation)}</p>
        </div>
    );
};

export default memo(StatusBadge);
