import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ArrowTopRightIcon } from '@radix-ui/react-icons';
import { Command } from 'cmdk';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

import { itemClassName } from '../components/FunnelItem';
import SectionHeading from '../components/SectionHeading';

import type { GroupCommand } from '@/app/cmdk/types';
import type { KeyboardEvent } from 'react';

interface Props {
    title: string;
    onSelect: (value: string, isExternal?: boolean) => void;
    itemOnKeyDown: (
        evt: KeyboardEvent<HTMLDivElement>,
        value: string,
        isExternal?: boolean,
    ) => void;
    commands: GroupCommand[];
    isAdmin?: boolean;
}

export const CommandGroup = ({ title, onSelect, itemOnKeyDown, commands, isAdmin }: Props) => {
    const { t } = useTranslation('common');

    const handleSelect = useCallback(
        (isExternal: boolean) => (value: string) => {
            onSelect(value, isExternal);
        },
        [onSelect],
    );

    const handleKeyDown = useCallback(
        (value: string, isExternal: boolean) => (evt: KeyboardEvent<HTMLDivElement>) => {
            itemOnKeyDown(evt, value, isExternal);
        },
        [itemOnKeyDown],
    );

    const hasNonAdminCommands = commands.some((command) => !command?.isAdmin);

    if (!isAdmin && !hasNonAdminCommands) {
        return null;
    }

    return (
        <Command.Group heading={<SectionHeading>{title}</SectionHeading>} className="text-gray-600">
            {commands.map((command) => {
                const {
                    id,
                    translationKey,
                    icon: Icon,
                    isInternal,
                    isExternal,
                    isAdmin: isAdminCommand,
                } = command;
                const value = t(translationKey) + ` ${id}`;

                if (isAdminCommand && !isAdmin) {
                    return null;
                }

                return (
                    <Command.Item
                        key={id}
                        className={itemClassName}
                        onSelect={handleSelect(isExternal)}
                        value={value}
                        tabIndex={0}
                        onKeyDown={handleKeyDown(value, isExternal)}
                    >
                        <div className="flex flex-1 items-center py-1">
                            <Icon className="size-5 text-gray-500" />
                            <span className="pl-3">{t(translationKey)}</span>
                        </div>
                        {(isInternal || isExternal) && (
                            <span className="text-gray-500">
                                {isInternal ? (
                                    <ChevronRightIcon className="size-5" />
                                ) : (
                                    <ArrowTopRightIcon className="size-4 -translate-x-1" />
                                )}
                            </span>
                        )}
                    </Command.Item>
                );
            })}
        </Command.Group>
    );
};
