import { Command } from 'cmdk';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';

import { useAppSelector } from '@/core/redux/hooks';
import ButtonLoadingSpinner from '@/ui/components/ButtonLoadingSpinner';

import { getLoading } from '../../models/campaigns';
import { getSearch, getType } from '../../models/menu';
import { CommandMenuType } from '../../types';

export interface Props {
    handleSearch: (search: string) => void;
}

const Header = ({ handleSearch }: Props) => {
    const { t } = useTranslation('common');
    const type = useAppSelector(getType);
    const search = useAppSelector(getSearch);
    const loading = useAppSelector(getLoading);

    const placeholder =
        type === CommandMenuType.workspace
            ? t('cmdk-funnels-input-placeholder')
            : t('cmdk-default-input-placeholder');

    return (
        <div className="relative flex h-16 items-center border-b p-4">
            <Command.Input
                className="flex-1 pr-8 outline-none"
                placeholder={placeholder}
                onValueChange={handleSearch}
                autoFocus
            />
            {loading && !!search && (
                <ButtonLoadingSpinner className="absolute inset-y-0 right-6 my-auto text-blue-500" />
            )}
        </div>
    );
};

export default memo(Header);
