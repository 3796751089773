import { UserIcon } from '@heroicons/react/24/outline';
import { memo } from 'react';

export interface Props {
    numOfContacts: number;
}

const ContactsBadge = ({ numOfContacts }: Props) => (
    <div className="inline-flex h-7 items-center justify-center gap-1 rounded-md bg-gray-100 py-1 pl-1 pr-2 text-gray-700 group-data-[selected]:bg-gray-200">
        <UserIcon className="w-4" />
        <span className="text-xs font-medium">{numOfContacts}</span>
    </div>
);

export default memo(ContactsBadge);
